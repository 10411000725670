<template>
    <div class="modal fade" id="commission-update-modal-id" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header  ms-modal-header-radius-0">
                    <h4 class="modal-title text-primary">Update Commission</h4>
                    <button type="button" id="close-modal" class="btn btn-danger close" data-bs-dismiss="modal"
                            aria-hidden="true">x
                    </button>
                </div>
                <div class="modal-body p-0 text-left">
                    <div class="col-xl-12 col-md-12">
                        <div class="ms-panel ms-panel-bshadow-none">
                            <div class="ms-panel-body p-4">
                                <form class="needs-validation" @submit.prevent="update()">
                                    <div class="form-row row">
                                        <div class="col-md-6 mb-3">
                                            <label for="visit_fee">Amount</label>
                                            <div class="input-group">
                                                <input readonly v-model="form.visit_fee" type="number"
                                                       class="form-control" id="visit_fee" placeholder="Amount">
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="discount_percent">Commission %</label>
                                            <div class="input-group">
                                                <input @keyup="getCommissionAmount()" step="any"
                                                       v-model="form.discount_percent"
                                                       type="number" class="form-control" id="discount_percent"
                                                       placeholder="Amount" required>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="final_total">Commission Amount</label>
                                            <div class="input-group">
                                                <input step="any" :max="form.visit_fee" v-model="form.final_total"
                                                       type="number" class="form-control" id="final_total"
                                                       placeholder="Amount" required>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary mt-4 d-inline w-20">
                                        Update
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:void(0)" class="d-none" id="btn-commission-update-modal" data-bs-toggle="modal"
           data-bs-target="#commission-update-modal-id"></a>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";

export default {
    name : "CommissionUpdate",
    props: ['from'],
    data : () => ({
        editMode: false,
        form    : {
            transaction_id  : 0,
            visit_fee       : 0,
            final_total     : 0,
            discount_percent: 0
        },

    }),

    methods: {
        getCommissionAmount() {
            let final_total = this.form.visit_fee * (this.form.discount_percent / 100)
            if (this.form.visit_fee >= final_total) {
                this.form.final_total = final_total
            } else {
                NotificationService.error("The Commission Amount must be a Main Amount equal or less than.");
            }
        },
        openPaymentModal() {
            document.querySelectorAll("#btn-commission-update-modal")[0].click();
        },
        update() {
            ApiService.update(`/transaction-commission-update/${this.form.transaction_id}`, this.form).then((res) => {
                NotificationService.success(res.data.message);
                this.formReset();
                this.$parent.getList();
                document.querySelectorAll("#close-modal")[0].click();
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                transaction_id  : 0,
                visit_fee       : 0,
                final_total     : 0,
                discount_percent: 0
            }
        }
    }
}
</script>
