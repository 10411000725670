<template>
    <div class="">
        <form @submit.prevent="getList()">
            <div class="mb-3 row g-2">
                <div class="col-sm-5">
                    <date-range-picker class="w-100" title="Date Range" v_model="date_range"/>
                </div>
                <div class="col-sm-5">
                    <drop-down title="Doctor" v_model="doctor"/>
                </div>
                <div class="col-sm-2">
                    <input type="submit" class="btn btn-primary w-100" value="Submit">
                </div>
            </div>
        </form>
        <div class="row">
            <div class="table-responsive">
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th scope="col">SL</th>
                        <th scope="col">Date</th>
                        <th scope="col">Doctor</th>
                        <th scope="col">Details</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Commission</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="lists.length">
                        <tr v-for="(list, index) in lists" class="align-middle">
                            <th scope="row">{{ table.pagination.from + index }}</th>
                            <td>{{ list.transaction_date | dateFormat }}</td>
                            <td @click="openCommissionUpdateModal(list)">
                            <span class="btn btn-sm btn-success text-white" v-if="list.doctor">
                                 {{ list.doctor.name }}<br>
                                Commission: {{ list.discount_percent }}%
                            </span>
                            </td>
                            <td>Invoice:{{ list.invoice_no }}</td>
                            <td>{{ list.visit_fee | numberFormat }}</td>
                            <td>{{ list.final_total | numberFormat }}</td>
                        </tr>
                    </template>
                    <template v-if="!lists.length">
                        <tr>
                            <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                        </tr>
                    </template>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colspan="4">Total</th>
                        <th>{{ getTotalAmount |numberFormat }}</th>
                        <th>{{ getTotalCommission |numberFormat }}</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
        </div>
        <commission-update ref="commission_update_modal" v-if="commission_update_modal"/>
    </div>
</template>

<script>
import DateRangePicker     from "@/components/picker/DateRangePicker";
import moment              from "moment";
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";
import CommissionUpdate    from "./CommissionUpdate";

export default {
    name      : "Commission",
    components: {CommissionUpdate, DateRangePicker, Pagination},
    data      : () => ({
        commission_update_modal: false,
        form                   : {
            date_range: [
                moment().startOf('month').format("YYYY-MM-DD"),
                moment(new Date()).format("YYYY-MM-DD"),
            ],
            doctor    : '',
        },
        errors                 : [],
        lists                  : [],
        table                  : {
            per_page  : 10,
            date_range: '',
            user      : '',
            pagination: {
                current_page: 1
            }
        }
    }),
    computed  : {
        getTotalAmount() {
            let total_amount = 0;
            this.lists.forEach((item, i) => {
                total_amount += parseFloat(item.visit_fee);
            });
            return total_amount;
        },
        getTotalCommission() {
            let total_amount = 0;
            this.lists.forEach((item, i) => {
                total_amount += parseFloat(item.final_total);
            });
            return total_amount;
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        openCommissionUpdateModal(transaction) {
            this.commission_update_modal = true;
            const component_created      = setInterval(() => {
                if (this.$refs['commission_update_modal']) {
                    this.$refs['commission_update_modal'].form = {
                        transaction_id  : transaction.id,
                        visit_fee       : transaction.visit_fee,
                        final_total     : transaction.final_total,
                        discount_percent: transaction.discount_percent
                    };
                    this.$refs['commission_update_modal'].openPaymentModal();
                    clearInterval(component_created);
                }
            }, 100);
        },
        getList() {
            let params = {
                ...this.table,
                page      : this.table.pagination.current_page,
                date_range: this.form.date_range,
                doctor_id : this.form.doctor ? this.form.doctor.id : '',
            };
            ApiService.get('/accounts/commission', {params: params}).then(({data}) => {
                this.lists            = data.data;
                this.table.pagination = data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
    }
}
</script>
