<template>
    <div class="">
        <form @submit.prevent="store()">
            <div class="mb-3 row g-2">
                <div class="col-sm-3">
                    <DatePicker class="w-100" :disabled_date="disabledDateByDoctorAvailabilities" title="Please select a date"
                                v_model="transaction_date"/>
                    <div :class_name="{ 'is-invalid': errors['transaction_date'] }" v-if="errors['transaction_date']"
                         class="invalid-feedback">
                        {{ errors['transaction_date'][0] }}
                    </div>
                </div>
                <div class="col-sm-3">
                    <input placeholder="Details" v-model="form.note" type="text"
                           class="form-control" id="note">
                </div>
                <div class="col-sm-3">
                    <input :class="{ 'is-invalid': errors['final_total'] }" placeholder="Please give the Amount"
                           v-model="form.transaction_payments.amount" type="number"
                           class="form-control" id="final_total">
                    <div v-if="errors['final_total']" class="invalid-feedback">
                        {{ errors['final_total'][0] }}
                    </div>
                </div>
                <div class="col-sm-3">
                    <input type="submit" class="btn btn-primary w-100" value="Save">
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import DatePicker          from "@/components/picker/DatePicker";
import moment              from "moment";
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";

export default {
    name      : "IncomeExpenseCreate",
    props     : {
        type: {type: String},
    },
    components: {DatePicker},
    data      : () => ({
        form  : {
            transaction_date    : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            discount_type       : 'fixed',
            note                : '',
            final_total         : 0,
            transaction_payments: {
                amount: ''
            }
        },
        errors: []
    }),
    methods   : {
        disabledDateByDoctorAvailabilities(date) {
            //start from today's
            const today = new Date();
            today.setHours(24, 0, 0, 0);
            return date >= today;
        },
        store() {
            let form = {
                ...this.form,
                type       : this.type,
                final_total: this.form.transaction_payments.amount,
                sub_total  : this.form.transaction_payments.amount
            }
            ApiService.post('/transactions', form).then((res) => {
                this.errors = []
                this.formReset();
                this.$parent.getList();
                NotificationService.success(res.data.message);
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                type                : 'expense',
                transaction_date    : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                discount_type       : 'fixed',
                note                : '',
                final_total         : 0,
                transaction_payments: {
                    amount: ''
                }
            }
        }
    }
}
</script>
