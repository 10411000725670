<template>
    <div class="accounts-section mt-sm-5 mt-3">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li v-if="$root.$can('accounts', 'accounts')" class="nav-item" role="presentation">
                            <button @click="account_component='Accounts'" class="nav-link active"
                                    id="pills-accounts-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-accounts" type="button" role="tab"
                                    aria-controls="pills-accounts"
                                    aria-selected="true">Accounts
                            </button>
                        </li>
                        <li v-if="$root.$can('accounts', 'earning')" class="nav-item" role="presentation">
                            <button @click="account_component='Income'" class="nav-link" id="pills-earning-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-accounts" type="button" role="tab"
                                    aria-controls="pills-earning" aria-selected="false">Earning
                            </button>
                        </li>
                        <li v-if="$root.$can('accounts', 'expense')" class="nav-item" role="presentation">
                            <button @click="account_component='Expense'" class="nav-link" id="pills-expense-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-accounts" type="button" role="tab"
                                    aria-controls="pills-expense" aria-selected="false">Expense
                            </button>
                        </li>
                        <li v-if="$root.$can('accounts', 'commission')" class="nav-item" role="presentation">
                            <button @click="account_component='Commission'" class="nav-link" id="pills-commission-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-accounts" type="button" role="tab"
                                    aria-controls="pills-commission" aria-selected="false">Commission
                            </button>
                        </li>
                        <li v-if="$root.$can('accounts', 'salary')" class="nav-item" role="presentation">
                            <button @click="account_component='Salary'" class="nav-link" id="pills-salary-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-accounts" type="button" role="tab"
                                    aria-controls="pills-salary" aria-selected="false">Salary
                            </button>
                        </li>
                    </ul>
                    <div class="bg-opacity-10 bg-primary p-2 p-sm-5 tab-content" id="pills-tabContent">
                        <!-- accounts-->
                        <div class="tab-pane fade show active" id="pills-accounts" role="tabpanel"
                             aria-labelledby="pills-accounts-tab">
                            <component :is="account_component"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Commission from "./Commission";
import Expense    from "./Expense";
import Income     from "./Income";
import Accounts   from "./Accounts";
import Salary     from "./Salary";

export default {
    name      : "Index",
    components: {Accounts, Income, Expense, Commission, Salary},
    data      : () => ({
        account_component: 'Accounts'
    }),
}
</script>

<style scoped>
.table > :not(:first-child) {
    border-top: 0 solid currentColor;
}
</style>
